import React from "react"
import { Checkbox } from "./Checkbox"

import "./CheckList.css"

export const CheckList = ({ options, checkedItems, onCheckItem }) => {
  return (
    <div className="CheckList">
      {options.map(({ label, name }) => (
        <Checkbox
          label={label}
          key={name}
          checked={checkedItems.includes(name)}
          onCheck={(...args) => onCheckItem(name, ...args)}
        />
      ))}
    </div>
  )
}
