import React, { useCallback } from "react"
import classNames from "classnames"

import "./Checkbox.css"

export const Checkbox = ({ label, checked, onCheck, className, ...props }) => {
  const handleChange = useCallback(
    event => {
      onCheck(event.target.checked, event)
    },
    [onCheck]
  )
  return (
    <div className={classNames("Checkbox", className)}>
      <span className="Checkbox__check-wrapper">
        <input
          className="Checkbox__check js-focus-visible"
          type="checkbox"
          checked={checked}
          onChange={handleChange}
          aria-label={label}
        />
        <svg
          className="Checkbox__check-icon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
            fill="currentColor"
          />
        </svg>
      </span>
      <label className="Checkbox__label">{label}</label>
    </div>
  )
}
