import React, { useState } from "react"
import Layout from "../components/layout"
import { TextInput } from "../components/form/TextInput"
import { CheckList } from "../components/form/CheckList"

import "./contact.css"
import { TextArea } from "../components/form/TextArea"
import { Dropdown } from "../components/form/Dropdown"
import { Button } from "../components/Button"
import { HeaderVariants, HeaderRight } from "../components/header"
import { BackgroundImage } from "../components/BackgroundImage"

const interests = [
  {
    label: "Cloud Solutions",
    name: "cloud",
  },
  {
    label: "Data Analytics & AI",
    name: "data-ai",
  },
  {
    label: "Internet of Things (IoT)",
    name: "iot",
  },
  {
    label: "Cybersecurity",
    name: "security",
  },
  {
    label: "Hardware & Infrastructure",
    name: "infra-hardware",
  },
]

const referralOptions = [
  {
    label: "Friends",
    name: "friends",
  },
  {
    label: "Internet",
    name: "internet",
  },
  {
    label: "Referral",
    name: "referral",
  },
  {
    label: "Twitter",
    name: "twitter",
  },
  {
    label: "Instagram",
    name: "instagram",
  },
]

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    company: "",
    interests: [],
    hopesAndDreams: "",
    referrer: "",
  })
  const checkInterest = (interest, checked) => {
    if (checked) {
      if (!formData.interests.includes(interest)) {
        setFormData(formData => ({
          ...formData,
          interests: [...formData.interests, interest],
        }))
      }
    } else {
      setFormData(formData => ({
        ...formData,
        interests: formData.interests.filter(
          selectedInterest => selectedInterest !== interest
        ),
      }))
    }
  }
  const activeReferrer = referralOptions.find(
    option => option.name === formData.referrer
  )
  return (
    <form>
      <div className="contact-page-section">
        <h1 className="contact-page-section__title">let's get started</h1>
        <p className="contact-page-section__description">
          Get in touch to start a conversation with us. We review every inquiry
          and respond promptly.
        </p>
        <TextInput
          value={formData.name}
          onChange={name => setFormData({ ...formData, name })}
          label="Name*"
        />
        <TextInput
          value={formData.email}
          type="email"
          onChange={email => setFormData({ ...formData, email })}
          label="Email Address*"
        />
        <TextInput
          value={formData.phoneNumber}
          onChange={phoneNumber => setFormData({ ...formData, phoneNumber })}
          label="Phone Number*"
        />
        <TextInput
          value={formData.company}
          onChange={company => setFormData({ ...formData, company })}
          label="Company / Organization*"
        />
      </div>
      <div className="contact-page-section">
        <h1 className="contact-page-section__title">services of Interest*</h1>
        <p className="contact-page-section__description">
          Please select from the services below.
        </p>
        <CheckList
          options={interests}
          checkedItems={formData.interests}
          onCheckItem={checkInterest}
        />
        <TextArea
          value={formData.hopesAndDreams}
          onChange={hopesAndDreams =>
            setFormData({ ...formData, hopesAndDreams })
          }
          label="What do you hope to achieve?"
          style={{ marginBottom: "3.39rem" }}
        />
        <p className="dropdown__label">How did you hear about us?</p>
        <Dropdown
          items={referralOptions}
          activeItem={activeReferrer}
          onClickItem={referrer => setFormData({ ...formData, referrer })}
        />
        <Button style={{ marginTop: "3rem" }}>submit</Button>
      </div>
    </form>
  )
}

const ContactUsPage = () => {
  return (
    <Layout
      headerVariant={HeaderVariants.dark}
      pageTitle="Contact Us"
      fixedFooter
    >
      <div className="container contact-us-page footer-fixed-adjust">
        <div className="two-paned-view">
          <div className="left-pane header-fixed-adjust">
            <ContactForm />
          </div>
          <div className="right-pane contact-page-image-container">
            <div className="contact-page-image">
              <BackgroundImage
                className="contact-page-image-background"
                imageName="contact.jpg"
              />
              <div className="contact-page-nav">
                <HeaderRight
                  variant={HeaderVariants.light}
                  pageTitle="Contact"
                />
              </div>
              <div className="contact-info">
                <div className="contact-info-section">
                  <h6 className="contact-info-title">Address</h6>
                  <p className="contact-info-text">
                    2nd Floor, Tapa House, Imam Dauda street,
                  </p>
                  <p className="contact-info-text">
                    Off Eric Moore Road, Surulere.
                  </p>
                  <p className="contact-info-text">Lagos</p>
                </div>
                <div className="contact-info-section">
                  <h6 className="contact-info-title">We're here to help</h6>
                  <p className="contact-info-text">
                    Our support team is available Monday - Friday, 8 AM - 4PM,
                  </p>
                  <p className="contact-info-text">
                    We have extended hours for customers who are on a Premium
                  </p>{" "}
                  <p className="contact-info-text">Support.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactUsPage
