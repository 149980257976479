import React, { useState } from "react"
import classNames from "classnames"

import "./Dropdown.css"

import dropdownIcon from "../../images/dropdown_arrow.png"

export const Dropdown = ({
  items,
  activeItem,
  placeholder = "",
  onClickItem,
  className,
}) => {
  const [open, setOpen] = useState(false)
  const triggerText = !!activeItem ? activeItem.label : placeholder

  const handleItemClick = (item, ...args) => {
    setOpen(false)
    onClickItem(item, ...args)
  }
  const toggleDropdown = () => {
    setOpen(!open)
  }
  const handleKeyUp = event => {
    /* Escape key pressed */
    if (event.keyCode === 27) {
      setOpen(false)
    }
  }
  return (
    <div className={classNames("Dropdown", className)} onKeyUp={handleKeyUp}>
      <button
        className={classNames(
          "Dropdown__trigger js-focus-visible",
          open && "Dropdown__trigger--active"
        )}
        onClick={toggleDropdown}
        type="button"
      >
        <span className="Dropdown__trigger-text">{triggerText}</span>
        <img
          alt=""
          role="presentational"
          className="Dropdown__trigger-icon"
          src={dropdownIcon}
        />
      </button>
      {open && (
        <div
          className={classNames(
            "Dropdown__list",
            open && "Dropdown__list--open"
          )}
        >
          {items.map(({ label, name }) => (
            <button
              className={classNames(
                "Dropdown__list-item",
                !!activeItem &&
                  name === activeItem.name &&
                  "Dropdown__list-item--active"
              )}
              onClick={(...args) => handleItemClick(name, ...args)}
              type="button"
            >
              <span className="Dropdown__list-item-content">{label}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}
